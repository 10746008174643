import request from "@/utils/request";
import XLSX from 'xlsx'
import JsonExcel from 'vue-json-excel'
import { saveAs } from 'file-saver';
import E from "wangeditor";
const {ElMessage} = require("element-plus");

export default {

    name: "kddInfo",
    components: {
        // JsonExcel
    },

    data() {
        const userData = JSON.parse(window.sessionStorage.getItem("user"));
        const userName = userData.username;
        return {

            loading: true,
            disabled: false,
            judge: false,
            dialogVisible: false,
            detailDialog: false,
            search: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            detail: {},
            form: {
                id: "",
                deliverytime:"",
                customer: "",
                writers: "",
                creationtime: "",
                customerID: "",
                finish: "",
                audit: "",
                remark: "",
                collection: "",
                payment: "",
                serve: "",
                demand: "",
                manner: "",
                settlement: "",
                incentive: "",
                ordernumber: "",
            },
            defaultCustomer: userName,

            rules: {
                ordernumber: [
                    { required: true, message: '请输入订单编号', trigger: 'blur' },
                ],
                incentive: [
                    { required: true, message: '请选择', trigger: 'blur' },
                ],
                serve: [
                    { required: true, message: '请选择', trigger: 'blur' },
                ],
                customerID: [
                    { required: true, message: '请填写', trigger: 'blur' },
                ],
                collection: [
                    { required: true, message: '请填写', trigger: 'blur' },
                ],
                demand: [
                    { required: true, message: '请填写', trigger: 'blur' },
                ],
                // writers: [
                //     { required: true, message: '请填写', trigger: 'blur' },
                // ],
                // payment: [
                //     { required: true, message: '请填写', trigger: 'blur' },
                // ],
                manner: [
                    { required: true, message: '请选择', trigger: 'blur' },
                ],

            },
        };
    },
    created() {
        this.load();
        this.loading = true;
        setTimeout(() => {
            //设置延迟执行
            this.loading = false;
        }, 1000);
    },
    methods: {
        exportTable() {
            const data = this.tableData; // 要导出的数据数组
            const columns = [
                { header: '订单编号', key: 'ordernumber' },
                { header: '创建时间', key: 'creationtime' },
                { header: '客户id', key: 'customerID' },
                { header: '收款金额', key: 'collection' },
                { header: '写手', key: 'writers' },
                { header: '派单金额', key: 'payment' },
                { header: '完成状态', key: 'finish' },
                { header: '审核状态', key: 'audit' },
                { header: '审核时间', key: 'deliverytime' },
                { header: '结算状态', key: 'settlement' },
                { header: '收款渠道', key: 'manner' },
                { header: '备注', key: 'remark' },
            ];

            const ws = XLSX.utils.json_to_sheet(data, { header: columns.map(col => col.key) });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, '订单信息表');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, '订单信息表.xlsx');
        },
        // 其他已有的方法，如load、reset、add等保持不变
        updateDeliveryTime() {
            if (this.form.audit === '已审核') {
                // 当选择"已审核"时，更新最终确认时间为当前时间
                const localTime = new Date();
                const chinaTime = new Date(localTime.getTime() + (8 * 60 * 60 * 1000)); // 增加8小时的毫秒数
                const formattedTime = chinaTime.toISOString().slice(0, 19).replace('T', ' ');

                this.form.deliverytime = formattedTime;
            } else {
            // 当选择"未审核"时，清空最终确认时间
            this.form.deliverytime = null;
        }
        },


        async load() {
            const userData = JSON.parse(window.sessionStorage.getItem("user"));
            const userName = userData.username;

            request.get("/building/kfind", {

                params: {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    search: this.search,
                    userName:userName,
                },
            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        closeDetailDialog() {
            this.detailDialog = false;
        },
        reset() {
            this.search = ''
            alert(1)
            request.get("/building/kfind", {
                params: {
                    pageNum: 1,
                    pageSize: this.pageSize,
                    search: this.search,
                    userName:userName,

                },
            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        filterTag(value, row) {
            return row.finish === value;
        },
        filterTag3(value, row) {
            return row.incentive === value;
        },
        filterTag1(value, row) {
            return row.audit === value;
        },
        filterTag2(value, row) {
            return row.settlement === value;
        },
        add() {
            const userData = JSON.parse(window.sessionStorage.getItem("user"));
            const userName = userData.username;
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.disabled = false;
                this.form = {};
                this.form.finish = '未完成'; // 设置默认值为"未完成"
                this.form.audit = '未审核'; // 设置默认值为"未审核"
                this.form.settlement = '未结算'; // 设置默认值为"未结算"
                this.form.customer = userName; // 设置默认值为"dorm1"
                const localTime = new Date();
                const chinaTime = new Date(localTime.getTime() + (8 * 60 * 60 * 1000)); // 增加8小时的毫秒数
                const formattedTime = chinaTime.toISOString().slice(0, 19).replace('T', ' ');

                this.form.creationtime = formattedTime; // 设置创建时间为格式化后的值


                this.judge = false;
                this.judge = false;
            });
        },
        async getWriterSuggestions(query) {
            try {
                const response = await axios.get(`/api/getWriterSuggestions?query=${query}`);
                if (response.data && Array.isArray(response.data)) {
                    return response.data;
                } else {
                    // 处理无效响应或其他错误情况
                    console.error("无效的响应或错误");
                    return [];
                }
            } catch (error) {
                // 处理网络请求错误
                console.error("网络请求错误", error);
                return [];
            }
        },
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.judge === false) {

                        //新增
                        request.post("/building/add", this.form).then((res) => {
                            console.log(res);
                            if (res.code === "0") {
                                ElMessage({
                                    message: "新增成功",
                                    type: "success",
                                });
                                this.search = "";
                                this.load();
                                this.dialogVisible = false;
                            } else {
                                ElMessage({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                        });
                    } else {
                        //修改
                        request.put("/building/update", this.form).then((res) => {
                            console.log(res);
                            if (res.code === "0") {
                                ElMessage({
                                    message: "修改成功",
                                    type: "success",
                                });
                                this.search = "";
                                this.load();
                                this.dialogVisible = false;
                            } else {
                                ElMessage({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.dialogVisible = false;
        },
        showDetail(row) {
            this.detailDialog = true;
            this.$nextTick(() => {
                this.detail = row;
            });
        },
        handleEdit(row) {
            //修改
            this.judge = true;
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                // 生拷贝
                this.form = JSON.parse(JSON.stringify(row));
                this.disabled = true;
            });
        },
        handleDelete(id) {
            console.log();
            request.delete("/building/delete/" + id).then((res) => {
                if (res.code === "0") {
                    ElMessage({
                        message: "删除成功",
                        type: "success",
                    });
                    this.search = "";
                    this.load();
                } else {
                    ElMessage({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },
        handleSizeChange(pageSize) {
            //改变每页个数
            this.pageSize = pageSize;
            this.load();
        },
        handleCurrentChange(pageNum) {
            //改变页码
            this.currentPage = pageNum;
            this.load();
        },
    },
};